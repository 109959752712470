.office-contact,
.office-contact .data-contact .infos {
    display: flex;
    flex-direction: column;
}

.office-contact .data-contact {
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.office-contact .data-contact .specific-contact {
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.office-contact h1 {
    gap: 10px;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 12px 0 ;
    color: var(--secondary-color);
}

.office-contact .infos h2 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--secondary-color);
}

.office-contact .infos h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
}