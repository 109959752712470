@import url('https://fonts.googleapis.com/css2?family=Marcellus:wght@100;200;300;400;500;600;700;800;900&display=swap');

.section-title {
    gap: 8px;
    width: 100%;
    margin: auto;
    display: flex;
}

.section-title.both {
    align-items: center;
}

.section-title.right {
    align-items: baseline;
}

.section-title.left {
    align-items: end;
}

.section-title.none {
    align-items: center;
}

.section-title h1 {
    gap: 30px;
    display: flex;
    font-size: 48px;
    font-weight: 400;
    align-items: center;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-family: "Marcellus" !important;
}

.section-title h4 {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: var(--secondary-color);
}

@media (max-width: 820px) {
    .section-title h1 {
        font-size: 42px;
    }

    .section-title h4 {
        font-size: 12px;
    }
}

@media (max-width: 645px) {
    .section-title.right {
        width: 95vw;
    }

    .section-title h1 {
        gap: 20px;
        font-size: 32px;
        font-weight: 500;
    }
}

@media (max-width: 540px) {
    .section-title h1 {
        gap: 15px;
        font-size: 24px;
    }
}