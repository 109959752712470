section#office {
    margin: auto;
    width: 100vw;
    height: auto;
    background-position: 70vh;
    background-repeat: no-repeat;
    background-image: url('https://advocaciadc.adv.br/db/images/white_background.webp');
}

section#office .content {
    gap: 20px;
    width: 1100px;
    display: flex;
    flex-direction: column;
}

section#office .section-title {
    gap: 5px;
}

section#office .about-office {
    gap: 15px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

section#office .about-office h6 {
    gap: 10px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: justify;
    flex-direction: column;
    color: var(--secondary-color);
}

section#office .about-office img {
    width: 500px;
    height: 665px;
    object-fit: cover;
    pointer-events: none;
}

@media (max-width: 1160px) {
    section#office .about-office img {
        width: 42vw;
        height: 665px;
    }
}

@media (max-width: 965px) {
    section#office .about-office {
        gap: 8px;
        flex-direction: column;
    }

    section#office .about-office img {
        width: 100%;
        height: 800px;
        object-position: 50% 2%;
    }

    section#office .about-office h6 {
        font-size: 12px;
        font-weight: 500;
    }
}

@media (max-width: 645px) {
    section#office .section-title {
        flex-direction: column !important;
    }

    section#office .about-office img {
        height: 500px;
    }
}

@media (max-width: 460px) {
    section#office .about-office img {
        height: 400px;
    }
}