section#header {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position-y: 30%;
    background-repeat: no-repeat;
    background-image: url('https://advocaciadc.adv.br/db/images/header_background.webp');
}

section#header .menu {
    width: 100vw;
    height: auto;
    display: flex;
    padding-top: 20px;
    justify-content: center;
}

section#header nav {
    margin: auto;
    cursor: pointer;
}

section#header nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
}

section#header nav li {
    padding: 2vh;
}

section#header nav a {
    display: block;
    font-size: 13px;
    font-weight: 500;
    transition: 0.3s;
    position: relative;
    color: var(--white);
    letter-spacing: 1.5px;
    text-decoration: none;
    text-transform: uppercase;
}

section#header nav a:hover {
    color: var(--main-color);
}

section#header nav a::after {
    content: "";
    left: 0;
    bottom: 0;
    width: 0%;
    height: 3px;
    position: absolute;
    margin-bottom: -1.5vh;
    transition: 0.5s ease-in-out;
    background-color: var(--main-color);
}

section#header nav a:hover::after {
    width: 100%;
}

section#header .content {
    width: 1100px;
    height: auto;
    margin: auto;
    display: flex;
    margin-top: 15vh;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

section#header img {
    pointer-events: none;
}

section#header .info {
    gap: 8px;
    width: 700px;
    height: 100%;
    display: flex;
    text-align: end;
    align-items: flex-end;
    flex-direction: column;
}

section#header .content h1 {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--main-color);
    text-transform: uppercase;
}

section#header .content h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
    color: var(--white);
}

section#header .content h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--white);
    letter-spacing: -0.2px;
}

section#header button.fast-contact {
    width: 350px;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondary-color);
    background-color: var(--main-color);
}

@media (max-width: 1160px) {
    section#header img {
        width: 316px;
    }

    section#header .content h1 {
        font-size: 12px;
    }

    section#header .content h2 {
        font-size: 38px;
        line-height: 45px;
    }

    section#header .content h4 {
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width: 990px) {
    section#header img {
        width: 286px;
    }

    section#header .content h1 {
        font-size: 12px;
    }

    section#header .content h2 {
        font-size: 32px;
        line-height: 38px;
    }

    section#header .content h4 {
        font-size: 12px;
        line-height: 18px;
    }

    section#header button.fast-contact {
        width: 300px;
        height: 40px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        text-transform: uppercase;
        color: var(--secondary-color);
        background-color: var(--main-color);
    }
}

@media (max-width: 850px) {
    section#header .content {
        gap: 50px;
        width: 90vw !important;
        flex-direction: column;
        padding-bottom: 50px;
    }

    section#header .info {
        width: 100%;
        text-align: start;
        align-items: baseline;
    }

    section#header img {
        width: 376px;
    }

    section#header {
        height: auto;
    }

    section#header button.fast-contact {
        width: 100%;
        margin-top: 8px;
    }
}

@media (max-width: 770px) {
    section#header .content {
        margin-top: 0;
        padding: 100px 0 50px 0;
    }

    section#header .menu {
        padding: 0;
        display: none;
    }
}

@media (max-width: 560px) {
    section#header .info {
        gap: 5px;
    }

    section#header img {
        width: 316px;
    }

    section#header .content h2 {
        font-size: 30px;
        line-height: 38px;
    }
}

@media (max-width: 500px) {
    section#header .content h2 {
        font-size: 22px;
        line-height: 27px;
    }

    section#header .content h4 {
        text-align: justify;
    }
}

/* ---------- Menu responsive up to 590px ---------- */
@media (max-width: 590px) {
    nav a {
        font-size: 1.5vh;
    }

    nav ul {
        display: block;
    }

    .nav-mobile {
        width: 100%;
        display: block;
    }

    .nav-mobile-icon {
        padding: 1vh;
        margin-left: 1.5vh;
        color: #c0bc91;
    }

    .nav-global {
        padding: 0;
        width: 100%;
        display: block;
    }

    .nav-desktop {
        display: none;
        margin-left: 1.5vh;
        margin-top: -1vh;
    }

    .nav-desktop.show {
        position: absolute;
        display: block;
    }

    #logo {
        position: relative;
    }
}