section#instagram {
    width: 1100px;
    overflow: hidden;
}

section#instagram .social {
    gap: 10px;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
}

section#instagram .social h3 {
    gap: 15px;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    align-items: center;
    flex-direction: row;
    text-transform: lowercase;
}

section#instagram #instafeed,
section#instagram #instafeedtwo {
    gap: 15px;
    display: flex;
    flex-direction: row;
}

section#instagram #instafeed img,
section#instagram #instafeedtwo img {
    object-fit: cover;
    width: 280px !important;
    height: 350px !important;
}

section#instagram .swiper {
    width: 100%;
}

@media (max-width: 1160px) {
    section#instagram {
        width: 90vw;
    }
}

@media (max-width: 470px) {
    section#instagram .social h3 {
        font-size: 15px;
    }
}