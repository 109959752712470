section#our-team {
    margin: auto;
    width: 100vw;
    height: auto;
    background-size: cover;
    background-position-y: 90%;
    background-repeat: no-repeat;
    background-image: url('https://advocaciadc.adv.br/db/images/blue_background.webp')
}

section#our-team .content {
    gap: 35px;
    width: 1100px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

section#our-team .section-title {
    gap: 0;
}

section#our-team .section-title h1 {
    color: var(--white);
}

section#our-team .section-title h4 {
    color: var(--main-color);
}

section#our-team .swiper {
    width: 100%;
}

section#our-team .swiper-slide {
    gap: 7px;
    width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

section#our-team .swiper h1 {
    font-size: 20px;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 0.3px;
}

section#our-team .swiper h4 {
    font-size: 12px;
    font-weight: 200;
    color: var(--white);
    letter-spacing: 1px;
}

section#our-team .swiper .info {
    padding: 0 6px 0 6px;
}

section#our-team .swiper img {
    width: 280px;
    height: 410px;
    object-fit: cover;
    border-radius: 8px;
}

section#our-team .swiper-button-prev,
section#our-team .swiper-button-next {
    opacity: 0.2;
    color: var(--white);
    transition: all 0.5s ease-in-out;
}

section#our-team .swiper-button-prev:hover,
section#our-team .swiper-button-next:hover {
    opacity: 1;
}

@media (max-width: 1200px) {
    section#our-team {
        background-position-x: 40%;
    }
}

@media (max-width: 645px) {
    section#our-team .section-title {
        gap: 8px !important;
        flex-direction: column !important;
    }

    section#our-team .swiper-button-prev,
    section#our-team .swiper-button-next {
        display: none;
    }
}