section#footer {
    width: 100vw;
    height: auto;
    display: flex;
    padding: 15px 0 15px 0;
    background-color: var(--secondary-color);
}

section#footer .content {
    gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

section#footer .socials {
    gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

section#footer h6 {
    font-size: 12px;
    font-weight: 300;
    color: var(--white);
}

section#footer .socials .icon {
    display: flex;
    cursor: pointer;
}

@media (max-width: 710px) {
    section#footer .content {
        flex-direction: column-reverse;
    }

    section#footer h6 {
        text-align: center;
    }
}