section#areas-of-activity {
    width: 100vw;
    height: auto;
    margin: auto;
    background-color: var(--white);
}

section#areas-of-activity .content {
    width: 1100px;
    display: flex;
    margin: auto;
    flex-direction: column;
    background-color: var(--white);
}

section#areas-of-activity .custom-title-section {
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
}

section#areas-of-activity .custom-title-section .title {
    gap: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

section#areas-of-activity .custom-title-section h4 {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: var(--secondary-color);
}

section#areas-of-activity .areas {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

section#areas-of-activity .areas .area {
    gap: 3px;
    width: 365px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 10px 0 10px;
}

section#areas-of-activity .areas .area h1 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
}

section#areas-of-activity .areas .area h4 {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary-color);
}

@media (max-width: 1160px) {
    section#areas-of-activity .areas .area {
        width: 33%;
    }
}

@media (max-width: 830px) {
    section#areas-of-activity .areas .area {
        width: 49%;
    }
}

@media (max-width: 600px) {
    section#areas-of-activity .areas .area {
        width: 100%;
        padding: 35px 0 0 0;
    }
}