section#contact {
    margin: auto;
    width: 100vw;
    height: auto;
    background-position: 70vh;
    background-repeat: no-repeat;
    background-image: url('https://advocaciadc.adv.br/db/images/white_background.webp');
}

section#contact .section-title {
    gap: 0;
}

section#contact .office-contact,
section#contact .office-contact .data-contact .infos {
    display: flex;
    flex-direction: column;
}

section#contact .office-contact .data-contact {
    gap: 15px;
    display: flex;
    flex-direction: column;
}

section#contact .office-contact .data-contact .specific-contact {
    gap: 10px;
    display: flex;
    flex-direction: row;
}

section#contact .office-contact h1 {
    gap: 10px;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 12px 0 ;
    color: var(--secondary-color);
}

section#contact .office-contact .infos h2 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--secondary-color);
}

section#contact .office-contact .infos h3 {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
}

section#contact .data-and-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 900px) {
    section#contact .data-and-form {
        flex-direction: column;
    }
}

@media (max-width: 540px) {
    section#contact .office-contact h1 {
        font-size: 16px;
    }

    section#contact .office-contact .infos h2 {
        font-size: 11px;
    }

    section#contact .office-contact .infos h3 {
        font-size: 14px;
    }
}