section#social-networks {
    margin: auto;
    width: 100vw;
    height: auto;
    background-size: cover;
    background-position-y: 90%;
    background-repeat: no-repeat;
    background-image: url('https://advocaciadc.adv.br/db/images/blue_background.webp')
}

section#social-networks .section-title {
    gap: 0;
}

section#social-networks .section-title h1 {
    color: var(--white);
}

section#social-networks .section-title h4 {
    color: var(--main-color);
}

@media (max-width: 1200px) {
    section#social-networks {
        background-position-x: 40%;
    }
}