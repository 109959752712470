section#contact form {
    gap: 15px;
    display: flex;
    margin-top: 20px;
    align-items: end;
    flex-direction: column;
}

section#contact form input,
section#contact form textarea {
    width: 100%;
    border: none;
    display: flex;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
}

section#contact form textarea {
    resize: none;
    height: 120px;
}

section#contact form .custom-input {
    gap: 8px;
    width: 460px;
    display: flex;
    padding: 12px;
    border-radius: 8px;
    align-items: center;
    flex-direction: row;
    background-color: #EAE8E8;
}

section#contact form .custom-input.message {
    align-items: flex-start !important;
}

section#contact form h1 {
    font-size: 16px;
    font-weight: 700;
    text-align: end;
    color: var(--secondary-color);
}

section#contact button {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondary-color);
    background-color: var(--main-color);
}

@media (max-width: 1060px) {
    section#contact form,
    section#contact form .custom-input {
        width: 400px;
    }
}

@media (max-width: 960px) {
    section#contact form,
    section#contact form .custom-input {
        width: 350px;
    }
}

@media (max-width: 900px) {
    section#contact form {
        width: 100%;
        margin-top: 30px;
    }

    section#contact form .custom-input {
        width: 100%;
    }
}